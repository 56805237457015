import keyBy from 'lodash/keyBy'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { LiveSearchStatus } from 'src/components/LiveSearch'
import { CUSTOMER_SERVICE_VIEW_POLL_PERIOD_MS, PRIORITIES } from 'src/constants'
import {
  CancelationReason,
  OffNominalReason,
  PriorityReason,
} from 'src/models/order'
import {
  CreditCheckOverrideReason,
  Order,
  OrderMethod,
  OrderPreview,
} from 'src/models/order'
import * as OrderService from 'src/services/OrderService'

import LocalCacheStore from './LocalCacheStore'

const AWAITING_ESIGNATURE_POLL_PERIOD_MS = 1000 * 60 // 1 minute

class OrdersStore extends LocalCacheStore<OrderPreview, Order> {
  @observable orderMethods?: OrderMethod[]
  @observable creditCheckOverrideReasons?: CreditCheckOverrideReason[]
  @observable searchStatus: LiveSearchStatus = LiveSearchStatus.READY
  @observable cancelationReasons?: CancelationReason[]
  @observable priorityReasons?: PriorityReason[]
  @observable offNominalReasons?: OffNominalReason[]
  @observable needsSignatureOrders: OrderPreview[] = []
  @observable payers: string[] = []

  needsSignatureOrdersPollData = {
    active: false,
    interval: AWAITING_ESIGNATURE_POLL_PERIOD_MS,
    lastRefreshTimeStamp: 0,
  }

  constructor() {
    super()
    makeObservable(this)
  }

  get pollConfig() {
    return {
      poll: true,
      name: 'orders',
      interval: CUSTOMER_SERVICE_VIEW_POLL_PERIOD_MS,
    }
  }

  get properties(): (keyof this)[] {
    return [
      'entityList',
      'detailedEntities',
      'orderMethods',
      'creditCheckOverrideReasons',
    ]
  }

  @computed get cancelationReasonByValue():
    | Record<string, CancelationReason>
    | undefined {
    if (this.cancelationReasons === undefined) {
      return this.cancelationReasons
    }

    return keyBy(this.cancelationReasons, 'value')
  }

  // Implementation of abstract methods.
  protected async refreshEntityList(): Promise<OrderPreview[]> {
    // Fetch all open orders
    const orders = await OrderService.getOrders()

    // In parallel, fetch orders awaiting signature
    if (
      !this.needsSignatureOrdersPollData.active &&
      Date.now() - this.needsSignatureOrdersPollData.lastRefreshTimeStamp >
        this.needsSignatureOrdersPollData.interval
    ) {
      this.needsSignatureOrdersPollData.active = true
      OrderService.getOrders({ needsSignature: true })
        .then((orders) => {
          this.needsSignatureOrdersPollData.lastRefreshTimeStamp = Date.now()
          runInAction(() => {
            this.needsSignatureOrders = orders
          })
        })
        .finally(() => {
          this.needsSignatureOrdersPollData.active = false
        })
    }

    return orders
  }

  protected getEntity(entityId: string): Promise<Order> {
    return OrderService.getOrder(entityId)
  }

  protected createEntity(order: Order): Promise<Order> {
    return OrderService.createOrder(order)
  }

  protected async updateEntity(order: Order): Promise<Order> {
    const updatedOrder = await OrderService.updateOrder(order)
    return updatedOrder
  }

  protected deleteEntity(order: Order): Promise<Order> {
    return OrderService.cancelOrder(
      order.id,
      order.requestedProducts[0].cancelation!
    )
  }

  @computed get loadingOrderDetails(): boolean {
    return (
      this.selectedEntityId !== undefined && this.selectedEntity === undefined
    )
  }

  @computed get orderPriority(): string | undefined {
    if (!this.selectedEntity) {
      return undefined
    }

    return PRIORITIES[this.selectedEntity.priority].label
  }

  @action.bound async getOrderMethods(): Promise<OrderMethod[]> {
    const orderMethods = await OrderService.getOrderMethods()
    runInAction(() => (this.orderMethods = orderMethods))
    return orderMethods
  }

  @action.bound async getPayers(): Promise<string[]> {
    const payers = await OrderService.getPayers()
    runInAction(() => (this.payers = payers))
    return payers
  }

  @action.bound async getCreditCheckOverrideReasons(): Promise<
    CreditCheckOverrideReason[]
  > {
    const overrideReasons = await OrderService.getCreditCheckOverrideReasons()
    runInAction(() => (this.creditCheckOverrideReasons = overrideReasons))
    return overrideReasons
  }

  @action.bound async selectOrder(
    orderId?: string,
    reset?: () => void,
    refresh?: boolean
  ): Promise<void> {
    // prevent querying what we're already looking at
    if (!refresh && orderId === this.selectedEntityId) {
      return
    }

    // if selecting an order, clear everything
    if (!refresh) {
      this.searchStatus = LiveSearchStatus.READY
      reset?.()
    }

    await this.refresh(orderId)
  }

  @action.bound async searchOrder(
    orderId: string,
    reset?: () => void
  ): Promise<void> {
    const id = orderId.trim()

    // don't search empty strings
    if (!id) {
      return
    }

    this.searchStatus = LiveSearchStatus.LOADING

    let status = LiveSearchStatus.SUCCESS
    try {
      await this.refresh(id)
    } catch (e) {
      status = LiveSearchStatus.ERROR
    }

    runInAction(() => {
      this.searchStatus = status
      reset?.()
    })
  }

  @action.bound cancelOrder(orderId: string, reasonName: string): Order {
    const order = this.detailedEntities[orderId]

    // Override the cancelation reasons with the given reason.
    order.requestedProducts = order.requestedProducts.map((request) => ({
      ...request,
      cancelation: reasonName,
    }))
    this.remove(orderId)
    return order
  }

  @action.bound async getPriorityReasons(): Promise<PriorityReason[]> {
    const priorityReasons = await OrderService.getPriorityReasons()
    runInAction(() => (this.priorityReasons = priorityReasons))
    return priorityReasons
  }

  @action.bound async getCancelationReasons(): Promise<CancelationReason[]> {
    const cancelationReasons = await OrderService.getCancelationReasons()
    runInAction(() => (this.cancelationReasons = cancelationReasons))
    return cancelationReasons
  }

  @action.bound async getOffNominalReasons(): Promise<CancelationReason[]> {
    const offNominalReasons = await OrderService.getOffNominalReasons()
    runInAction(() => (this.offNominalReasons = offNominalReasons))
    return offNominalReasons
  }

  getPriorityReasonNameByShortName(shortName: string): string {
    const priorityReasonName = this.priorityReasons?.find(
      (reason) => reason.value === shortName
    )?.label
    return priorityReasonName || ''
  }
}

export default new OrdersStore()
